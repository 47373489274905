import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=1ccb1ce7&scoped=true"
import script from "./Loader.vue?vue&type=script&lang=js"
export * from "./Loader.vue?vue&type=script&lang=js"
import style0 from "./Loader.vue?vue&type=style&index=0&id=1ccb1ce7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ccb1ce7",
  null
  
)

export default component.exports