import { render, staticRenderFns } from "./NewCommission.vue?vue&type=template&id=65e0f204&scoped=true"
import script from "./NewCommission.vue?vue&type=script&lang=js"
export * from "./NewCommission.vue?vue&type=script&lang=js"
import style0 from "./NewCommission.vue?vue&type=style&index=0&id=65e0f204&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e0f204",
  null
  
)

export default component.exports