var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{staticClass:"Credential-Form",attrs:{"data-vv-scope":"Credential-Form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitCredentialForm.apply(null, arguments)}}},[_c('md-card',[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-xsmall-size-100 md-medium-size-100 md-size-50"},[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has('Credential-Form.apiKey'),
                  }},[_c('label',{attrs:{"for":"apiKey"}},[_vm._v(" "+_vm._s(_vm.$tc("phrases.apiKey"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"id":"apiKey","name":"apiKey"},model:{value:(_vm.credentialService.credential.apiKey),callback:function ($$v) {_vm.$set(_vm.credentialService.credential, "apiKey", $$v)},expression:"credentialService.credential.apiKey"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first("Credential-Form.apiKey"))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has('Credential-Form.username'),
                  }},[_c('label',{attrs:{"for":"username"}},[_vm._v(" "+_vm._s(_vm.$tc("words.username"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"id":"username","name":"username"},model:{value:(_vm.credentialService.credential.username),callback:function ($$v) {_vm.$set(_vm.credentialService.credential, "username", $$v)},expression:"credentialService.credential.username"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first("Credential-Form.username"))+" ")])],1)],1),_c('div',{staticClass:"md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100"},[_c('md-field',{class:{
                    'md-invalid': _vm.errors.has('Credential-Form.apiToken'),
                  }},[_c('label',{attrs:{"for":"shortCode"}},[_vm._v(" "+_vm._s(_vm.$tc("phrases.shortCode"))+" ")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"id":"shortCode","name":"shortCode"},model:{value:(_vm.credentialService.credential.shortCode),callback:function ($$v) {_vm.$set(_vm.credentialService.credential, "shortCode", $$v)},expression:"credentialService.credential.shortCode"}}),_c('span',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first("Credential-Form.shortCode"))+" ")])],1)],1)])]),_c('div',{staticClass:"md-layout md-gutter",staticStyle:{"padding":"2.5rem"}},[_c('div',{staticClass:"md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-layout-item--right"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" Incoming Messages URL: "),_c('p',{staticClass:"token-value"},[_vm._v(_vm._s(_vm.incomingMessagesUrl))])])]),_c('div',{staticClass:"md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-layout-item--right"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" Delivery Reports URL: "),_c('p',{staticClass:"token-value"},[_vm._v(_vm._s(_vm.deliveryReportsUrl))])])])])])]),(_vm.loading)?_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}}):_vm._e(),_c('md-card-actions',[_c('md-button',{staticClass:"md-raised md-primary",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$tc("words.save"))+" ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }