import { render, staticRenderFns } from "./PasswordProtection.vue?vue&type=template&id=3d9fdf40&scoped=true"
import script from "./PasswordProtection.vue?vue&type=script&lang=js"
export * from "./PasswordProtection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d9fdf40",
  null
  
)

export default component.exports